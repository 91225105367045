import { Network } from '@/lib/models/networks';
import { hexToAscii } from '@/lib/web3-utils';
import {
  DefinitionList,
  EIP712,
  GenericMessage,
  EIP155_SIGNING_METHODS,
  isEIP712,
  TransactionObject,
  RawMessage,
} from '@/lib/web3-access';

// @see {@link https://github.com/krakenfx/wallet/blob/main/modules/wallet-connect/web3Wallet/ethereum/utils/adaptToGenericMessage.ts}

/**
 * Extracts and sorts fields from a GenericMessage based on a specified order.
 * This function handles both simple key-value pairs and complex structured data
 * that might be formatted as newline-separated key-value pairs.
 *
 * @param {GenericMessage} message - The message object containing an array of message items.
 * @param {string[]} fields - An array of field names specifying the order and subset of fields to extract.
 * @returns {Array<{key: string, value: string}>} An array of objects with 'key' and 'value' properties, sorted according to the order specified in 'fields'.
 */
export function extractFields(message: GenericMessage, fields: string[]) {
  const fieldSet = new Set(fields);
  let extractedDetails: Array<{ key: string; value: string }> = [];

  for (const item of message.message) {
    if (fieldSet.has(item.title)) {
      // Handle simple key-value pairs directly
      extractedDetails.push({
        key: item.title,
        value: item.description,
      });
    } else if (typeof item.description === 'string' && item.description.includes('\n')) {
      // Check if the description contains structured data formatted as key-value pairs
      const detailItems = parseKeyValuePairs(item.description);
      extractedDetails.push(...detailItems.filter((detail) => fieldSet.has(detail.key)));
    }
  }

  // Sort the extracted details by their order in the fields array to maintain consistency in display
  extractedDetails.sort((a, b) => fields.indexOf(a.key) - fields.indexOf(b.key));
  return extractedDetails;
}

/**
 * Parses a description containing newline-separated key-value pairs into an array of objects.
 * This function is designed to handle descriptions that contain structured data formatted as key-value pairs.
 * @param {string} description - The string containing key-value pairs.
 * @returns {Array<{key: string, value: string}>} Parsed key-value pairs as objects.
 */
function parseKeyValuePairs(description: string): Array<{ key: string; value: string }> {
  return description.split('\n').map((detail) => {
    const [key, value] = detail.split(':').map((part) => part.trim());
    return { key, value };
  });
}

/**
 * Get a message detail by key
 *
 * @param {GenericMessage['message']} details - The message details.
 * @param {string} key - The key to get the detail for.
 * @returns {string | undefined} The detail value, or undefined if not found.
 */
export const getMessageDetail = (details: GenericMessage['message'], key: string): string | undefined => {
  return details.find((detail) => detail.title === key)?.description;
};

/**
 * Format the parameters for the scan transaction
 *
 * @param {string} method - The signing method.
 * @param {string | EIP712 | TransactionObject} rawParams - The raw parameters.
 * @param {string} address - The address.
 * @returns {string} The formatted parameters.
 */
export const formatParams = (
  method: string,
  rawParams: string | EIP712 | TransactionObject,
  address: string,
): string => {
  switch (method) {
    case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION: {
      return JSON.stringify(serializeBigInt([rawParams]));
    }
    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA:
    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3:
    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4: {
      return JSON.stringify([address, JSON.stringify(serializeBigInt(rawParams))]);
    }
    default: {
      throw new Error(`Unsupported signing method: ${method}`);
    }
  }
};

/**
 * Serializes bigInt values in an object to strings.
 * @param obj - The object to serialize.
 * @returns The serialized object.
 */
export const serializeBigInt = (obj: any): any => {
  if (typeof obj === 'bigint') {
    return obj.toString();
  }
  if (Array.isArray(obj)) {
    return obj.map((element) => serializeBigInt(element));
  }
  if (typeof obj === 'object' && obj !== null) {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, serializeBigInt(value)]));
  }
  return obj;
};

/**
 * Adds additional fields to the messages array based on provided parameters.
 * @param allMessages The existing array of message objects.
 * @param additionalParams Additional parameters to include in the messages.
 */
function addAdditionalFields(
  allMessages: Array<{ title: string; description: string }>,
  additionalParams: {
    dappName?: string;
    chainId?: string;
    dappSite?: string;
    vaultName?: string;
    userName?: string;
    memo?: string;
    expiryTimestamp?: number;
  },
): void {
  if (additionalParams.dappName) {
    allMessages.push({
      title: 'dapp',
      description: additionalParams.dappName,
    });
  }

  if (additionalParams.chainId) {
    const chainIdParts = additionalParams.chainId.split(':');
    const chainId = chainIdParts.length > 1 ? chainIdParts[1] : additionalParams.chainId;

    allMessages.push({
      title: 'chainId',
      description: chainId,
    });

    const network = Network.getNetworkByChainId(chainId);
    if (network) {
      allMessages.push({
        title: 'network',
        description: Network.getDisplayName(network),
      });
    }

    const currency = Network.getNetworkTickerByChainId(chainId);
    if (currency) {
      allMessages.push({
        title: 'currency',
        description: currency,
      });
    }
  }

  if (additionalParams.dappSite) {
    allMessages.push({
      title: 'site',
      description: additionalParams.dappSite,
    });
  }

  if (additionalParams.vaultName) {
    allMessages.push({
      title: 'vaultName',
      description: additionalParams.vaultName,
    });
  }

  if (additionalParams.userName) {
    allMessages.push({
      title: 'createdBy',
      description: additionalParams.userName,
    });
  }

  if (additionalParams.memo) {
    allMessages.push({
      title: 'memo',
      description: additionalParams.memo,
    });
  }

  allMessages.push({
    title: 'createdAt',
    description: Math.floor(Date.now() / 1000).toString(),
  });

  if (additionalParams.expiryTimestamp) {
    allMessages.push({
      title: 'sigDeadline',
      description: additionalParams.expiryTimestamp.toString(),
    });
  }
}

/**
 * Adapt various types of signing methods into a generic message format.
 * @param signMethod The signing method used.
 * @param requestParams Parameters associated with the signing request.
 * @param additionalParams Additional parameters including network identifier and application details.
 * @returns {GenericMessage} A GenericMessage object containing structured information about the message.
 */
export function adaptToGenericMessage(
  signMethod: string,
  requestParams: any,
  additionalParams: {
    chainId?: string;
    dappName?: string;
    dappSite?: string;
    vaultName?: string;
    userName?: string;
    memo?: string;
    expiryTimestamp?: number;
  },
): GenericMessage {
  let address = '';
  let heading: string | undefined;
  let method = signMethod;
  let _message: string;
  let message:
    | string
    | {
        title: string;
        description: string;
      }[] = '';
  let rawMessage: RawMessage = '';
  let primaryType: string | undefined;

  switch (signMethod) {
    case EIP155_SIGNING_METHODS.PERSONAL_SIGN: {
      [_message, address] = requestParams;
      heading = 'Sign';
      message = _message;
      rawMessage = _message;
      break;
    }
    case EIP155_SIGNING_METHODS.ETH_SIGN: {
      [address, _message] = requestParams;
      heading = 'Sign';
      message = _message;
      rawMessage = _message;
      break;
    }
    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA:
    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4: {
      const [, message_] = requestParams;

      if (typeof message_ === 'string') {
        try {
          const parsedMessage = JSON.parse(message_);

          if (isEIP712(parsedMessage)) {
            primaryType = parsedMessage.primaryType;
            heading = parsedMessage.primaryType;
            message = adaptEIP712ToDefinitionList(parsedMessage);
            rawMessage = parsedMessage;
          } else {
            message = message_;
            rawMessage = message_;
          }
        } catch {
          message = message_;
          rawMessage = message_;
        }
      } else if (isEIP712(message_)) {
        primaryType = message_.primaryType;
        heading = message_.primaryType;
        message = adaptEIP712ToDefinitionList(message_);
        rawMessage = message_;
      } else {
        throw new Error('Invalid message type');
      }

      [address] = requestParams;
      break;
    }
    case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION:
    case EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION: {
      const [transaction] = requestParams;
      heading = 'Transaction';
      address = transaction.from;
      message = adaptTransactionToDefinitionList(transaction);
      rawMessage = transaction;
      break;
    }
    default: {
      throw new Error(`Invalid sign method: ${signMethod}`);
    }
  }

  const allMessages =
    typeof message === 'string' ? [{ title: 'message', description: hexToAscii(message) }] : [...message];

  // Add primaryType if it exists
  if (primaryType) {
    allMessages.push({
      title: 'primaryType',
      description: primaryType,
    });
  }

  addAdditionalFields(allMessages, additionalParams);

  return {
    type: 'generic-message',
    address,
    heading,
    message: allMessages,
    rawMessage,
    method,
  };
}

/**
 * Converts EIP712 typed data into a list of definitions for display.
 * @param data The EIP712 data object containing domain and message properties.
 * @returns {DefinitionList} An array of definition objects with title and description properties.
 */
function adaptEIP712ToDefinitionList(data: EIP712): DefinitionList {
  const combineEntries = [...Object.entries(data.domain), ...Object.entries(data.message)];
  // eslint-disable-next-line unicorn/no-array-reduce
  return combineEntries.reduce<DefinitionList>((definitionList, [key, value]) => {
    if (value === null || typeof value !== 'object') {
      definitionList.push({ title: key, description: `${value}` });
    } else {
      const description = Object.entries(value)
        .map(([k, v]) => `${k}: ${typeof v === 'string' ? v : JSON.stringify(v)}`)
        .join('\n');
      definitionList.push({ title: key, description });
    }
    return definitionList;
  }, []);
}

/**
 * Converts transaction data into a list of definitions for display.
 * @param transaction The transaction object to be adapted.
 * @returns {DefinitionList} An array of definition objects with title and description properties.
 */
function adaptTransactionToDefinitionList(transaction: any): DefinitionList {
  const definitionList: DefinitionList = Object.entries(transaction).map(([key, value]) => ({
    title: key,
    description: `${value}`,
  }));

  const methodCode = transaction?.data?.slice(0, 10);
  if (methodCode) {
    definitionList.push({
      title: 'method',
      description: methodCode,
    });
  }

  return definitionList;
}
