import { graphql } from '@/lib/gql';

export const OperationGovernanceModelFragment = graphql(`
  fragment OperationGovernanceModel on OperationGovernance {
    __typename
    details {
      createdAt
      action {
        __typename
        userManagementDetails {
          __typename
          ... on ChangeUserRolesAction {
            previousRoles
            newRoles
          }
        }
      }
    }
  }
`);
