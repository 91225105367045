import { SignRequestMetadataSourceType, TransactionMetadataSourceType } from '@/lib/gql/graphql';

/**
 * Custom patterns for source keys.
 * As record is a string, it will handle both SignRequestMetadataSourceType and TransactionMetadataSourceType.
 */
const customPatterns: Record<string, string[]> = {
  [TransactionMetadataSourceType.OneInch]: ['oneinch', '1inch'],
  [TransactionMetadataSourceType.IoNetwork]: [
    'iofinnet',
    'io-finnet',
    'iodevnet'
  ],
  [TransactionMetadataSourceType.Aave]: ['aave', 'app.aave.com'],
  [TransactionMetadataSourceType.Uniswap]: ['uniswap', 'app.uniswap.org'],
  [TransactionMetadataSourceType.CowSwap]: ['cowswap', 'cow swap', 'swap.cow.fi'],
  [TransactionMetadataSourceType.ZeroXSplits]: ['0xsplits', 'zero xsplits', '0xsplits.com'],
  [TransactionMetadataSourceType.BungeExchange]: ['bungee.exchange', 'bungeeexchange'],
  [TransactionMetadataSourceType.LsdNetwork]: ['lsdnetwork', 'lsd.joinstakehouse.com'],
  [TransactionMetadataSourceType.Ethena]: ['ethena', 'ethena.fi'],
};

/**
 * Type defining the configuration for each source.
 */
export type SourceConfig = {
  key: SignRequestMetadataSourceType | TransactionMetadataSourceType;
  displayName: string;
  patterns: string[];
};

/**
 * Converts an enum value to a human-readable display name.
 * For example, 'IO_NETWORK' becomes 'Io Network', and 'WALLET_CONNECT' becomes 'Wallet Connect'.
 *
 * @param value - The enum value to convert.
 * @returns The formatted display name.
 */
function toDisplayName(value: string): string {
  return (
    value
      // Replace underscores with spaces
      .replaceAll('_', ' ')
      // Lowercase the string
      .toLowerCase()
      // Capitalize the first letter of each word
      .replaceAll(/(?:^|\s)\S/g, (a) => a.toUpperCase())
  );
}

/**
 * Converts an enum value to a snake_case pattern.
 * For example, 'IO_NETWORK' becomes 'io_network', and 'WALLET_CONNECT' becomes 'wallet_connect'.
 *
 * @param value - The enum value to convert.
 * @returns The snake_case pattern.
 */
function toPattern(value: string): string {
  return value.toLowerCase();
}

/**
 * Collects all unique values from both SignRequestMetadataSourceType and TransactionMetadataSourceType enums.
 */
const values = new Set<string>([
  ...Object.values(SignRequestMetadataSourceType),
  ...Object.values(TransactionMetadataSourceType),
]);

/**
 * Generates the sourceConfigs array by iterating over the combined values from both enums.
 * Automatically derives displayName and patterns based on the value.
 */
export const sourceConfigs: SourceConfig[] = [...values].map((value) => ({
  key: value as SignRequestMetadataSourceType | TransactionMetadataSourceType,
  displayName: toDisplayName(value),
  patterns: customPatterns[value] || [toPattern(value)],
}));

/**
 * Type guard to check if a key belongs to SignRequestMetadataSourceType.
 *
 * @param key - The key to check.
 * @returns True if the key exists in SignRequestMetadataSourceType, else false.
 */
export function isSignRequestSource(key: string): key is SignRequestMetadataSourceType {
  return Object.values(SignRequestMetadataSourceType).includes(key as SignRequestMetadataSourceType);
}

/**
 * Type guard to check if a key belongs to TransactionMetadataSourceType.
 *
 * @param key - The key to check.
 * @returns True if the key exists in TransactionMetadataSourceType, else false.
 */
export function isTransactionSource(key: string): key is TransactionMetadataSourceType {
  return Object.values(TransactionMetadataSourceType).includes(key as TransactionMetadataSourceType);
}
